import React from 'react'
import styled from 'styled-components'

import { Container } from 'common/UI'
import { Carousel } from 'common/UI/Carousel'
import { SelectedClinicsStoryblok } from 'common/types'
import { ClinicThumb } from 'modules/shared'

type Props = {
  block: SelectedClinicsStoryblok
}

export const SelectedClinics = ({ block, ...rest }: Props): JSX.Element => {
  const { items, variant } = block

  return (
    <Wrapper {...rest}>
      {variant === 'grid' && (
        <Grid>
          {items?.map((clinic) => {
            return (
              <ClinicThumb
                key={clinic.uuid}
                name={clinic.content?.name}
                city={clinic.content?.city}
                postalCode={clinic.content?.postal_code}
                image={clinic.content?.image?.filename}
                phoneNumber={clinic.content?.phone_number}
                contactLink={clinic.content?.contact_page?.cached_url}
                link={
                  clinic.content?.homepage?.cached_url
                    ? clinic.content?.homepage?.cached_url
                    : '/'
                }
              />
            )
          })}
        </Grid>
      )}
      {variant === 'slider' && (
        <Carousel itemsPerView={2}>
          {items?.map((clinic) => (
            <ClinicThumb
              key={clinic.uuid}
              name={clinic.content?.name}
              city={clinic.content?.city}
              postalCode={clinic.content?.postal_code}
              image={clinic.content?.image?.filename}
              phoneNumber={clinic.content?.phone_number}
              contactLink={clinic.content?.contact_page?.cached_url}
              link={
                clinic.content?.homepage?.cached_url
                  ? `/${clinic.content?.homepage?.cached_url}`
                  : '/'
              }
            />
          ))}
        </Carousel>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Grid = styled(Container)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);

    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`
